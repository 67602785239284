import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { useForm, Controller } from "react-hook-form";
import ReactSelect from "react-select";

import { connect, useDispatch, useSelector } from "react-redux";
import { fetchCancerDriverList, fetchSearchParameters } from "../../actions/index";


import Papa from "papaparse";
//import geneNameData from './data/functional_element_with_snv_by_cancer_merged_May_15_2019_unique_geneName.csv';
import geneNameData from './data/functional_element_with_snv_by_cancer_merged_Mar_22_2022_unique_geneName.csv';

/* eslint-disable no-unused-vars */

  const options1 = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" }
  ];

  const options2 = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" }
  ];

  let geneSymbolOptions;
  Papa.parse(geneNameData, {
                                header: true,
                                delimiter: ",",
                                download: true,
                                dynamicTyping: true,
                                complete: function(results) {
                                console.log("Parsing complete", results);
                                geneSymbolOptions = results.data
                                }
                            });
  console.log("papa parsed file hook form", geneSymbolOptions)


  const elementTypeOptions = [
    { value: 'promoter', label: 'promoter' },
    { value: '5UTR', label: '5\'UTR' },
    { value: '3UTR', label: '3\'UTR' },
    { value: 'enhancer', label: 'enhancer' },
    { value: 'splice site', label: 'splice site' },
    { value: 'lncRNA', label: 'lncRNA' },
    { value: 'lncRNA promoter', label: 'lncRNA promoter' },
    { value: 'CTCF-cohesin insulator', label: 'CTCF-cohesin insulator' },
    { value: 'FMRE', label: 'FMRE, frequently mutated regulatory element' },
    { value: 'CRE', label: 'CRE, cis regulatory element' },
    { value: 'miRNA', label: 'miRNA' },
    { value: 'snRNA', label: 'snRNA' },
    { value: 'small RNA', label: 'small RNA' },
    { value: 'rRNA', label: 'rRNA' },
    { value: 'misc_RNA', label: 'misc_RNA' },
    { value: 'other noncoding regions', label: 'other noncoding regions' }
  ];
  
  const evidenceTypeOptions = [
    { value: 'computational prediction', label: 'computational prediction' },
    { value: 'gene expression association', label: 'RNA-seq gene expression association' },
    { value: 'experimental validation', label: 'other experimental validation' },
  ];


  const cancerTypeOptions = [
    { value: 'PanCancer', label: 'PanCancer' },
    { value: 'B-ALL', label: 'B-ALL, B-Cell Acute Lymphoblastic Leukemia' },
    { value: 'BLCA', label: 'BLCA, Bladder Urothelial Carcinoma' },
    { value: 'BRCA', label: 'BRCA, Breast Invasive Carcinoma' },
    { value: 'CLL', label: 'CLL, Chronic Lymphoctytic Leukemia' },
    { value: 'COAD', label: 'COAD, Colon Adenocarcinoma' },
    { value: 'DLBC', label: 'DLBC, Lymphoid Neoplasm Diffuse Large B-cell Lymphoma' },
    { value: 'ESCA', label: 'ESCA, Esophageal Carcinoma' },
    { value: 'ETMR', label: 'ETMR, Embryonal Tumor with Multilayered Rosettes' },
    { value: 'GBM', label: 'GBM, Glioblastoma Multiforme' },
    { value: 'HGG', label: 'HGG, High-grade Glioma' },
    { value: 'HNSC', label: 'HNSC, Head and Neck Squamous Cell Carcinoma' },
    { value: 'KICH', label: 'KICH, Kidney Chromophobe' },
    { value: 'KIRC', label: 'KIRC, Kidney Renal Clear Cell Carcinoma' },
    { value: 'LAML', label: 'LAML, Acute Myeloid Leukemia' },
    { value: 'LGG', label: 'LGG, Lower Grade Glioma' },
    { value: 'LIHC', label: 'LIHC, Liver Hepatocellular Carcinoma' },
    { value: 'LUAD', label: 'LUAD, Lung Adenocarcinoma' },
    { value: 'LUSC', label: 'LUSC, Lung Squamous Cell Carcinoma' },
    { value: 'Lymph-BNHL', label: 'Lymph-BNHL, B-cell non-Hodgkin lymphoma' },
    { value: 'MALY', label: 'MALY, Malignant Lymphoma' },
    { value: 'MB', label: 'MB, Medullablastoma' },
    { value: 'OV', label: 'OV, Ovarian Serous Cystadenocarcinoma' },
    { value: 'PA', label: 'PA, Pilocytic Astrocytoma' },
    { value: 'PACA', label: 'PACA, Pancreatic Ductal Adenocarcinoma' },
    { value: 'Pan-Endocrine', label: 'Pan-Endocrine, Pancreatic Endocrine Neoplasmas' },
    { value: 'PRAD', label: 'PRAD, Prostate Adenocarcinoma' },
    { value: 'RECA', label: 'RECA, Kidney Renal Papillary Cell Carcinoma' },
    { value: 'RHAB', label: 'RHAB, Rhabdoid Tumor' },
    { value: 'SKCM', label: 'SKCM, Skin Cutaneous Melanoma' },
    { value: 'STAD', label: 'STAD, Stomach Adenocarcinoma' },
    { value: 'THCA', label: 'THCA, Papillary Thyroid Carcinoma' },
    { value: 'UCEC', label: 'UCEC, Uterine Corpus Endometrial Carcinoma' },
    { value: 'Pancan-no-skin-melanoma-lymph', label: 'Meta-cohort: Pancan-no-skin-melanoma-lymph, (Rheinbay et al., Nature 2020)'},
    { value: 'Carcinoma', label: 'Meta-cohort: Carcinoma, (Rheinbay et al., Nature 2020)' },
    { value: 'Adenocarcinoma', label: 'Meta-cohort: Adenocarcinoma, (Rheinbay et al., Nature 2020)' },
    { value: 'Squamous', label: 'Meta-cohort: Squamous, (Rheinbay et al., Nature 2020)' },
    { value: 'Sarcoma', label: 'Meta-cohort: Sarcoma, (Rheinbay et al., Nature 2020)' },
    { value: 'Giloma', label: 'Meta-cohort: Giloma, (Rheinbay et al., Nature 2020)' },
    { value: 'Hematopoietic system', label: 'Meta-cohort: Hematopoietic system, (Rheinbay et al., Nature 2020)' },
    { value: 'Digestive tract', label: 'Meta-cohort: Digestive tract, (Rheinbay et al., Nature 2020)' },
    { value: 'Kidney', label: 'Meta-cohort: Kidney, (Rheinbay et al., Nature 2020)' },
    { value: 'Lymphatic system', label: 'Meta-cohort: Lymphatic system, (Rheinbay et al., Nature 2020)' },
    { value: 'Breast', label: 'Meta-cohort: Breast, (Rheinbay et al., Nature 2020)' },
    { value: 'Female reproductive system', label: 'Meta-cohort: Female reproductive system, (Rheinbay et al., Nature 2020)' },
    { value: 'CNS', label: 'Meta-cohort: Central nerve system, (Rheinbay et al., Nature 2020)' },
    { value: 'MALY-DE', label: 'Malignant Lymphoma - DE' },
    { value: 'T-ALL ', label: 'T-cell acute lymphoblastic leukemia' },
  ];

  const publicationOptions = [
    { value: '23348506', label: 'Huang et al., Science, 2013 (PMID: 23348506)' },
    { value: '24092746', label: 'Khurana et al., Science, 2013 (PMID: 24092746)' },
    { value: '25383969', label: 'Fredriksson et al., Nature Genetics, 2014 (PMID: 25383969)' },
    { value: '25261935', label: 'Weinhold et al., Nature Genetics, 2014 (PMID: 25261935)' },
    { value: '26304545', label: 'Lochovsky et al., Nucleic Acids Research, 2015 (PMID: 26304545)' },
    { value: '26053494', label: 'Melton et al., Nature Genetics, 2015 (PMID: 26053494)' },
    { value: '26200345', label: 'Puente et al., Nature, 2015 (PMID: 26200345)' },
    { value: '26691984', label: 'Araya et al., Nature Genetics, 2016 (PMID: 26691984)' },
    { value: '27571262', label: 'Bailey et al., Nature Genetics, 2016 (PMID: 27571262)' },
    { value: '27064257', label: 'Fujimoto et al., Nature Genetics, 2016 (PMID: 27064257)' },
    { value: '27311963', label: 'Mularoni et al., Genome Biology, 2016 (PMID: 27311963)' },
    { value: '27135926', label: 'Nik-Zainal et al., Nature, 2016 (PMID: 27135926)' },
    { value: '28467829', label: 'Hayward et al., Nature, 2017 (PMID: 28467829)' },
    { value: '28362259', label: 'Juul et al., eLIFE, 2017 (PMID: 28362259)' },
    { value: '28128360', label: 'Lanzos et al., Scientific Reports, 2017 (PMID: 28128360)' },
    { value: '28658208', label: 'Rheinbay et al., Nature, 2017 (PMID: 28658208)' },
    { value: '29489754', label: 'Grobner et al., Nature, 2018 (PMID: 29489754)' },
    { value: '29662167', label: 'Wedge et al., Nature Genetics, 2018 (PMID: 29662167)' },
    { value: '30718927', label: 'Frankell et al., Nature Genetics, 2019 (PMID: 30718927)' },
    { value: '31078526', label: 'Liu et al., Cell Systems, 2019 (PMID: 31078526)' },
    { value: '31597163', label: 'Shuai et al., Nature, 2019 (PMID: 31597163)' },
    { value: '31597162', label: 'Suzuki et al., Nature, 2019 (PMID: 31597162)' },
    { value: '32025015', label: 'Rheinbay et al., Nature, 2020 (PMID: 32025015)' },
    { value: '31974375', label: 'Zhou et al., Nature Communications, 2020 (PMID: 31974375)' },
    { value: '31954095', label: 'Zhu et al., Molecular Cell, 2020 (PMID: 3195409)' },
    { value: '30606723', label: 'Cornish et al., Blood Advances, 2019 (PMID: 30606723)' },
    { value: '32632335', label: 'Liu et al., Nature Genetics, 2020 (PMID: 32632335)' },
    { value: '35389777', label: 'Dietlein et al., Science , 2022 (PMID: 35389777)' },
  ];


function useQuery() {
    return queryString.parse(useLocation().search);
}



export default function QueryHookForm() {
    
    let query = useQuery();
    console.log("query parm",query)


    const dataSearchParameters = useSelector( (state) => ( {...state.dataSearchParameters} ) )
    
    let initGeneSymbol = null;
    let initElementType = null;
    let initCancerType = null;
    let initEvidenceType = null;
    let initPMID = null;

      if( !Array.isArray(dataSearchParameters.geneSymbol) || !dataSearchParameters.geneSymbol.length ){ 
        dataSearchParameters.geneSymbol = null
      }else{
        initGeneSymbol = geneSymbolOptions.filter( data => ( dataSearchParameters.geneSymbol.includes(data.value)) )
        //console.log("hookform initGeneSymbol",initGeneSymbol)
      }
  
      if( !Array.isArray(dataSearchParameters.elementType) || !dataSearchParameters.elementType.length ){ 
        dataSearchParameters.elementType = null
      }else{
        initElementType = elementTypeOptions.filter( data => ( dataSearchParameters.elementType.includes(data.value)) )
        
        //console.log("hookform initElementType",initElementType)
      }
  
      if( !Array.isArray(dataSearchParameters.cancerType) || !dataSearchParameters.cancerType.length ){ 
        dataSearchParameters.cancerType = null
      }else{
        //initCancerType = dataSearchParameters.cancerType.map(data => ({value: data, label: data}))
        initCancerType = cancerTypeOptions.filter( data => ( dataSearchParameters.cancerType.includes(data.value)) )
        //console.log("hookform initCancerType",initCancerType)
      }
  
      if( !Array.isArray(dataSearchParameters.evidenceType) || !dataSearchParameters.evidenceType.length ){ 
        dataSearchParameters.evidenceType = null
      }else{
        initEvidenceType = evidenceTypeOptions.filter( data => ( dataSearchParameters.evidenceType.includes(data.value)) )
        //console.log("hookform initElementType",initElementType)
      }
  
      if( !Array.isArray(dataSearchParameters.pmid) || !dataSearchParameters.pmid.length ){ 
        dataSearchParameters.pmid = null
      }else{
        initPMID = publicationOptions.filter( data => ( dataSearchParameters.pmid.includes(data.value)) )
        //console.log("hookform initElementType",initPMID)
      }

    

    const formDefaultValues = {
        geneSymbol: dataSearchParameters.geneSymbol ? initGeneSymbol : [],
        elementType: dataSearchParameters.elementType ? initElementType : [],
        cancerType: dataSearchParameters.cancerType ? initCancerType : [],
        evidenceType: dataSearchParameters.evidenceType ? initEvidenceType : [],
        pmid: dataSearchParameters.pmid ? initPMID : [],
    };

  console.log("hookform formDefaultValues",formDefaultValues)  

  console.log("hookform search parameters selector", dataSearchParameters)
  //console.log("hookform search parameters elementType selector", dataSearchParameters.elementType)


  //console.log("hookform props", props)
  //console.log("hookform search parameters props", props.dataSearchParameters)

  const { handleSubmit, register, reset, control } = useForm();
  
  const dispatch = useDispatch();

  const onSubmit = (data) => {
      console.log("hookform onsubmit received values", data)

      const payload = {
        ...data,
        geneSymbol: Object.values(data.geneSymbol.map(t => t.value)),
        elementType: Object.values(data.elementType.map(t => t.value)),
        cancerType: Object.values(data.cancerType.map(t => t.value)),
        evidenceType: Object.values(data.evidenceType.map(t => t.value)),
        pmid: Object.values(data.pmid.map(t => t.value)),
      }; 

     console.log("hookform onsubmit payload", payload)

     setTimeout(() => {
        //alert(JSON.stringify(payload, null, 2));
        console.log("query payload", payload);
        //props.fetchGeneSummary(payload.geneSymbol);
        //props.fetchCancerDriverList(payload);
        dispatch(fetchCancerDriverList(payload));
        dispatch(fetchSearchParameters(payload));

        //setSubmitting(false);
      }, 1000);



  };

  const handleReset = () => {
      reset({
          geneSybmol: [],
          elementType: [],
          cancerType: [],
          evidenceType: [],
          pmid: []
      })
  }

  return (
    
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">

            <div className="form-group col-md-3">
                <div style={{ margin: "0.25rem 0" }}>
                    <label for="geneSymbol">Gene name</label>
                        <Controller
                            as={ReactSelect}
                            options={geneSymbolOptions}
                            defaultValue={formDefaultValues.geneSymbol}
                            isMulti
                            name="geneSymbol"
                            isClearable
                            control={control}
                        />
                </div>        
            </div>

            <div className="form-group col-md-3">
                <div style={{ margin: "0.25rem 0" }}>
                    <label for="elementType">Element type</label>
                        <Controller
                            as={ReactSelect}
                            options={elementTypeOptions}
                            defaultValue={formDefaultValues.elementType}
                            isMulti
                            name="elementType"
                            isClearable
                            control={control}
                        />
                </div>
            </div>

            <div className="form-group col-md-3">
                <div style={{ margin: "0.25rem 0" }}>
                   <label for="cancerType">Cancer type</label>
                        <Controller
                            as={ReactSelect}
                            options={cancerTypeOptions}
                            defaultValue={formDefaultValues.cancerType}
                            isMulti
                            name="cancerType"
                            isClearable
                            control={control}
                        />
                </div>         
            </div>

            <div className="form-group col-md-3">
                <div style={{ margin: "0.25rem 0" }}>
                    <label for="evidenceType">Evidence type</label>
                        <Controller
                            as={ReactSelect}
                            options={evidenceTypeOptions}
                            defaultValue={formDefaultValues.evidenceType}
                            isMulti
                            name="evidenceType"
                            isClearable
                            control={control}
                        />
                </div>    
            </div>



      </div>

      <div className="form-row">
            <div className="form-group col-md-3">
                <div style={{ margin: "0.25rem 0" }}>
                    <label for="publicationPMID">Publication, PMID</label>
                        <Controller
                            as={ReactSelect}
                            options={publicationOptions}
                            defaultValue={formDefaultValues.pmid}
                            isMulti
                            name="pmid"
                            isClearable
                            control={control}
                        />
                </div>        
            </div>

      </div>   


      <button type="submit" className="btn btn-primary mr-2">
          Submit
      </button>


      <button
         type="rest"
         className="btn btn-secondary" 
         onClick={handleReset}
      >
          Reset
      </button>


    </form>
  
  );

}

const mapStateToProps = ( state ) => {
    return { dataSearchParameters: state.dataSearchParameters };
}

connect()(QueryHookForm);

/* eslint-enable no-unused-vars */
