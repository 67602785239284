import React, { Component } from 'react';
//import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
//import { importMDX } from 'mdx.macro';
//import faqDocument from './faq.md';

//example to use react-markdown package
//https://stackoverflow.com/questions/42928530/how-do-i-load-a-markdown-file-into-a-react-component

//const Content = importMDX.sync('./faq.md')

//import ReactGoogleSlides from 'react-google-slides';

//import dataSummary_figure from './img/num_of_noncoding_driver_per_publication.png';
//import dataSummary_figure from './img/num_of_noncoding_driver_per_publication_2022.png';
import dataSummary_figure from './img/num_of_noncoding_driver_per_publication_2022_latest.png';

class Documentation extends Component {
  

  render() {
    
    

    return (
      <div className="container-fluid py-5 pl-5">
           <div className="row">
                
               <div className="col-lg-10">  
                    <h1><strong>Documentation</strong></h1>
                    <hr className="style1"/>
                    {/*<h4><strong>Table of contents</strong></h4>
                    <ul>
                        <li>What is CNCDatabase?</li>
                        <li>How do I cite the CNCDatabase?</li>
                        <li>How do I get update on new non-coding cancer driver list?</li>
                    </ul>*/}
                    <h4><strong>What is CNCDatabase?</strong></h4>
                    <hr/>
                    <p>Although there are many existing databases that provide the list of cancer
                       drivers at protein coding regions in the genome, there is no central
                        database dedicated to collection of information of cancer drivers
                         at non-coding regions. CNCDatabase aims to collect non-coding cancer
                          driver lists from publications and to complement the understanding
                           of cancer beyond protein coding regions.</p>
                    <br/>
                    <h4><strong>Current CNCDatabase Version?</strong></h4>
                    <hr/>
                    <ol>
                    <li>CNCDatabase Version 1.6 (Current version). (<strong>Release date: 09/29/2022</strong>)</li>
                    <div>What has been added to this version?</div>
                       <ul>
			<li>Fixed exporting to CSV missing data from some columns.</li>
                       </ul>
                    <br/>
                    <li>CNCDatabase Version 1.5 (Current version). (<strong>Release date: 08/10/2022</strong>)</li>
                    <div>What has been added to this version?</div>
                       <ul>
			<li>a new non-coding studie from Dietlein, Felix et al paper was curated and added to the CNCDatabse.</li>
                        <li>Updated figures in Home and Documentation page.</li> 
                        <li>Updated the documentation page with new version.</li>
                       </ul>
                    <br/>
                    <li>CNCDatabase Version 1.4 (Current version). (<strong>Release date: 03/24/2022</strong>)</li>
                    <div>What has been added to this version?</div>
                       <ul>
                        <li>Updated the documentation page with new version.</li>
                        <li>Updated the admin email for the submission page</li>
                        <li>Resized and fixed the stretch behaviour of the institution logo and the introduction section of the home page</li>
                       </ul>
                    <br/>
                    <li>CNCDatabase Version 1.3. (<strong>Release date: 03/20/2022</strong>)</li>
		    <div>What has been added to this version?</div>
                       <ul>
                        <li>Updated the backend database to correctly fetch the new added papers records</li>
                        <li>Updated the number of study to the correct number of study in the Download Page</li>  
                        <li>Updated figures in Home and Documentation page</li> 
                        <li>Updated footer with correct date</li>
                        <li>Updated gene names from Gene query to reflect the updated database</li>
                        <li>Updated gene names and cancer type from the drop-down menu from the search page to reflect the updated database</li>
                       </ul>
                    <br/>
                    <li>CNCDatabase Version 1.2. (<strong>Release date: 01/31/2022</strong>)</li>
		    <div>What has been added to this version?</div>
                       <ul>
                        <li>2 latest non-coding studies from 2 publications which were curated and added</li>
                        <ul>
                          <li>Liu Y, Li C, Shen S, et al and Cornish AJ, Hoang PH, Dobbins SE, et al</li>
                          <li>Information such non-coding cancer driver lists, functional elements and among other entries were collected and added</li>  
			</ul>
                        <li>Updated required packages to newer and more stable versions</li> 
                        <li>Updated Documentation page of CNCDatabase client frontend site to include version number and other relevant details</li> 
                        <li>Added new release of data of CNCDatabase</li>
			<li>Updated PostgreSQL database content and list of publications files under Download tab</li>
			<li>Placed the right Database schema design file under Download tab</li>
                       </ul>
                    </ol>
                    <br/>
                    <h4><strong>How do I cite the CNCDatabase?</strong></h4>
                    <hr/>
                    <p>Please cite</p> 
                    <p><strong>Liu,E.M., Martinez-Fundichely,A., Bollapragada,R., Spiewack,M. and Khurana,E.</strong> 
                      <a href="https://pubmed.ncbi.nlm.nih.gov/33095860/" target="_blank" rel="noopener noreferrer"> CNCDatabase: a database of non-coding cancer drivers </a> 
                         <i>Nucleic Acids Res</i>. (2020); doi:10.1101/nar/gkaa915</p>

                    <br/>
                    <h4><strong>What do the different cancer abbreviations in the Search page stand for ?</strong></h4>
                    <hr/>
                    <p>CNCDatabse includes: </p>
                       <br/>

                    <p>
                    <strong>Pan-cancer type:</strong> 
                       <ul>
                        <li><strong>PanCancer</strong>, 
                           (Khurana et al., Science 2013, 
                            Weinhold et al., Nature Genetics 2014,
                            Fredriksson et al., Nature Genetics 2014,     
                            Lochovsky et al., Nucleic Acids Research 2015,
                            Melton et al., Nature Genetics 2015,
                            Mularoni et al., Genome Biology 2016,        
                            Lanzos et al., Scientific Reports 2017,
                            Juul et al., eLIFE 2017,
                            Liu et al., Cell Systems 2019,
                            and              
                            Zhu et al., Molecular Cell 2020)
                            <br/>
                            <br/>
                            <ul>
                                <li>
                                  Khurana et al., Science 2013:

                                    <ul>
                                       <li>includes 
                                               Prostate Adenocarcinoma,
                                               Medullablastoma
                                               and 
                                               Breast Invasive Carcinoma
                                       </li>  
                                    </ul>

                                </li>

                                <br/>

                                <li>
                                  Weinhold et al., Nature Genetics 2014:
                                    <ul>
                                      <li>includes 
                                                Acute Lymphoblastic Leukemia,
                                                Acute Myeloid Leukemia,
                                                Pilocytic Astrocytoma,
                                                Bladder Urothelial Carcinoma,
                                                Breast Invasive Carcinoma,
                                                Chronic Lymphocytic Leukemia,
                                                Colon Adenocarcinoma,
                                                Rectum Adenocarcinoma,
                                                Glioblastoma Multiforme,
                                                Head and Neck Squamous Cell Carcinoma,
                                                Kidney Chromophobe,
                                                Kidney Renal Clear Cell Carcinoma,
                                                Lower Grade Glioma,
                                                Liver Hepatocellular Carcinoma,
                                                Lung Adenocarcinoma,
                                                Lung Squamous Cell Carcinoma,
                                                Lymphoma,
                                                Medullablastoma,
                                                Ovarian Serous Cystadenocarcinoma,
                                                Pancreatic Ductal Adenocarcinoma,
                                                Prostate Adenocarcinoma,
                                                Skin Cutaneous Melanoma,
                                                Thyroid Carcinoma
                                                and
                                                Uterine Corpus Endometrial Carcinoma
                                      </li>
                                    </ul>
                                </li>

                                <br/>

                                <li>
                                  Fredriksson et al., Nature Genetics 2014:

                                  <ul>
                                      <li>includes 
                                                Bladder Urothelial Carcinoma,
                                                Breast Invasive Carcinoma,
                                                Colorectal Cancer,
                                                Glioblastoma Multiforme,
                                                Head and Neck Squamous Cell Carcinoma,
                                                Kidney Chromophobe,
                                                Kidney Renal Clear Cell Carcinoma,
                                                Lower Grade Glioma,
                                                Lung Adenocarcinoma,
                                                Lung Squamous Cell Carcinoma,
                                                Prostate Adenocarcinoma,
                                                Skin Cutaneous Melanoma,
                                                Thyroid Carcinoma
                                                and
                                                Uterine Corpus Endometrial Carcinoma
                                      </li>
                                    </ul>

                                </li>

                                <br/>

                                <li>
                                  Lochovsky et al., Nucleic Acids Research 2015:

                                    <ul>
                                      <li>includes 
                                                Acute Lymphoblastic Leukemia,
                                                Acute Myeloid Leukemia,
                                                Breast Invasive Carcinoma,
                                                Chronic Lymphocytic Leukemia,
                                                Glial Tumor,
                                                Kidney Carcinoma,
                                                Liver Hepatocellular Carcinoma,
                                                Lung Adenocarcinoma,
                                                Lymphoma B-cell,
                                                Medullablastoma,
                                                Pancreatic Ductal Adenocarcinoma,
                                                Pilocytic Astrocytoma,
                                                Prostate Adenocarcinoma,
                                                and
                                                Stomach Adenocarcinoma
                                      </li>
                                    </ul>

                                </li>

                                <br/>

                                <li>
                                  Melton et al., Nature Genetics 2015:

                                    <ul>
                                      <li>includes 
                                                Breast Invasive Carcinoma,
                                                Glioblastoma Multiforme,
                                                Head and Neck Squamous Cell Carcinoma,
                                                Kidney Renal Clear Cell Carcinoma,
                                                Lower Grade Glioma,
                                                Lung Adenocarcinoma,
                                                Lung Squamous Cell Carcinoma,
                                                Ovarian Serous Cystadenocarcinoma
                                                and
                                                Uterine Corpus Endometrial Carcinoma
                                      </li>
                                    </ul>

                                </li>

                                <br/>

                                <li>
                                  Mularoni et al., Genome Biology 2016:

                                   <ul>
                                      <li>includes 
                                                Bladder Urothelial Carcinoma,
                                                Breast Invasive Carcinoma,
                                                Cervical Squamous Cell Carcinoma and Endocervical Adenocarcinoma,
                                                Chronic Lymphocytic Leukemia,
                                                Colorectal Cancer,
                                                Glioblastoma Multiforme,
                                                Head and Neck Squamous Cell Carcinoma,
                                                Kidney Chromophobe,
                                                Kidney Renal Clear Cell Carcinoma,
                                                Acute Myeloid Leukemia,
                                                Liver Hepatocellular Carcinoma,
                                                Lower Grade Glioma,
                                                Lung Adenocarcinoma,
                                                Lung Squamous Cell Carcinoma,
                                                Lymphoma B-cell,
                                                Medullablastoma,
                                                Ovarian Serous Cystadenocarcinoma,
                                                Pancreatic Ductal Adenocarcinoma,
                                                Pilocytic Astrocytoma,
                                                Prostate Adenocarcinoma,
                                                Skin Cutaneous Melanoma,
                                                Stomach Adenocarcinoma,
                                                Thyroid Carcinoma
                                                and
                                                Uterine Corpus Endometrial Carcinoma
                                      </li>
                                    </ul>

                                </li>        
                                
                                <br/>

                                <li>
                                  Lanzos et al., Scientific Reports 2017:

                                   <ul>
                                      <li>includes 
                                                Bladder Urothelial Carcinoma,
                                                Breast Invasive Carcinoma,
                                                Chronic Lymphocytic Leukemia,
                                                Colon Adenocarcinoma,
                                                Rectum Adenocarcinoma,
                                                Glioblastoma Multiforme,
                                                Head and Neck Squamous Cell Carcinoma,
                                                Kidney Chromophobe,
                                                Kidney Renal Clear Cell Carcinoma,
                                                Lower Grade Glioma,
                                                Liver Hepatocellular Carcinoma,
                                                Lung Adenocarcinoma,
                                                Lung Squamous Cell Carcinoma,
                                                Lymphoma B-cell,
                                                Medullablastoma,
                                                Pancreatic Ductal Adenocarcinoma,
                                                Pilocytic Astrocytoma,
                                                Prostate Adenocarcinoma,
                                                Skin Cutaneous Melanoma,
                                                Stomach Adenocarcinoma,
                                                Thyroid Carcinoma
                                                and
                                                Uterine Corpus Endometrial Carcinoma
                                      </li>
                                    </ul>

                                </li>

                                <br/>

                                <li>
                                  Juul et al., eLIFE 2017:

                                  <ul>
                                      <li>includes 
                                                Bladder Urothelial Carcinoma,
                                                Breast Invasive Carcinoma,
                                                Colon Adenocarcinoma,
                                                Rectum Adenocarcinoma,
                                                Glioblastoma Multiforme,
                                                Head and Neck Squamous Cell Carcinoma,
                                                Kidney Chromophobe,
                                                Kidney Renal Clear Cell Carcinoma,
                                                Lower Grade Glioma,
                                                Lung Adenocarcinoma,
                                                Lung Squamous Cell Carcinoma,
                                                Prostate Adenocarcinoma,
                                                Skin Cutaneous Melanoma,
                                                Thyroid Carcinoma
                                                and
                                                Uterine Corpus Endometrial Carcinoma
                                      </li>
                                    </ul>

                                </li>
                                
                                <br/>

                                <li>
                                  Liu et al., Cell Systems 2019:
                                  <ul>
                                      <li>includes Bladder Urothelial Carcinoma,
                                                  Breast Invasive Carcinoma, 
                                                  Colon Cancer, 
                                                  Esophageal Carcinoma,
                                                  Liver Hepatocellular Carcinoma,
                                                  Kidney Renal Clear Cell Carcinoma, 
                                                  Kidney Chromophobe,
                                                  Lung Adenocarcinoma,
                                                  Ovarian Serous Cystadenocarcinoma,
                                                  Pancreatic Ductal Adenocarcinoma,
                                                  Prostate Adenocarcinoma,
                                                  Papillary Thyroid Carcinoma 
                                                  Uterine Corpus Endometrial Carcinoma,
                                                  Head and Neck Squamous Cell Carcinoma,
                                                  Lung Squamous Cell Carcinoma,
                                                  Pilocytic Astrocytoma, 
                                                  Medullablastoma, 
                                                  Glioblastoma Multiforme,  
                                                  Lower Grade Glioma,
                                                  Lung Squamous Cell Carcinoma,
                                                  Chronic Myelomonocytic Leukaemia
                                                  and
                                                  Malignant Lymphoma

                                      </li>    
                                  </ul>  
                                </li>
                                
                                <br/>

                                <li>              
                                  Zhu et al., Molecular Cell 2020:
                                  <ul>
                                      <li>includes 
                                                  Pancreatic Ductal Adenocarcinoma,
                                                  Prostate Adenocarcinoma,
                                                  Breast Invasive Carcinoma,
                                                  Kidney Renal Clear Cell Carcinoma,
                                                  Medullablastoma,
                                                  Ovarian Serous Cystadenocarcinoma,
                                                  Pilocytic Astrocytoma, 
                                                  Pancreatic Endocrine Neoplasmas,
                                                  Stomach Adenocarcinoma,
                                                  Head and Neck Squamous Cell Carcinoma,
                                                  Papillary Thyroid Carcinoma,
                                                  Lung Squamous Cell Carcinoma,
                                                  Kidney Chromophobe,
                                                  Colorectal Cancer,
                                                  Uterine Corpus Endometrial Carcinoma,
                                                  Osteosarcoma,
                                                  Glioblastoma Multiforme, 
                                                  Leiomyosarcoma,
                                                  Lung Adenocarcinoma,
                                                  Cholangiocarcinoma,
                                                  Essential Thrombocythaemia,
                                                  Bladder Urothelial Carcinoma,
                                                  Lower Grade Glioma,
                                                  Cervical Squamous Cell Carcinoma,
                                                  Acute Myeloid Leukemia,
                                                  Breast Lobular Carcinoma,
                                                  Chordoma,
                                                  Chondroblastoma,
                                                  Breast Ductal Carcinoma in Situ,
                                                  Chronic Myelomonocytic Leukaemia
                                                  and
                                                  Cervical Adenocarcinoma
                                      </li>    
                                  </ul>  
                                </li>

                                <br/>

                             </ul>   
                        </li>
                            
                        <br/>

                        <li>Meta-cohort: <strong>Pancan-no-skin-melanoma-lymph</strong>, (Rheinbay et al., Nature 2020)</li>
                               <ul>
                                  <li>includes Bladder Urothelial Carcinoma,
                                               Cholangiocarcinoma,
                                               Breast Invasive Carcinoma, 
                                               Breast Lobular Carcinoma,
                                               Cervical Adenocarcinoma,
                                               Colorectal Cancer, 
                                               Esophageal Carcinoma,
                                               Kidney Renal Clear Cell Carcinoma, 
                                               Kidney Chromophobe,
                                               Liver Hepatocellular Carcinoma,
                                               Lung Adenocarcinoma,
                                               Ovarian Serous Cystadenocarcinoma,
                                               Pancreatic Ductal Adenocarcinoma,
                                               Pancreatic Endocrine Neoplasmas,
                                               Prostate Adenocarcinoma,
                                               Stomach Adenocarcinoma,
                                               Papillary Thyroid Carcinoma, 
                                               Uterine Corpus Endometrial Carcinoma,
                                               Head and Neck Squamous Cell Carcinoma,
                                               Cervical Squamous Cell Carcinoma, 
                                               Lung Squamous Cell Carcinoma,
                                               Pilocytic Astrocytoma, 
                                               Medullablastoma, 
                                               Glioblastoma Multiforme,  
                                               Lower Grade Glioma,
                                               Head and Neck Squamous Cell Carcinoma,
                                               Cervical Squamous Cell Carcinoma,
                                               Lung Squamous Cell Carcinoma,
                                               Chondroblastoma,
                                               Chordoma,
                                               Leiomyosarcoma,
                                               Osteosarcoma,
                                               Acute Myeloid Leukemia,
                                               Chronic Myelomonocytic Leukaemia
                                               and
                                               Essential Thrombocythaemia

                                  </li>    
                                </ul>  
                       </ul>
                    </p>
                    <p>
                    <strong>Meta-cohort cancer type:</strong>
                    <br/>
                    Assembly of multiple cancer types were used in the cancer driver identification
                    to increase discovery power: 
                    <br/>
                    <br/>
                      
                      <ul>
                        <strong>By cell type of origin:</strong>
                          <ul>
                            <li>Meta-cohort: <strong>Carcinoma</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Bladder Urothelial Carcinoma,
                                               Cholangiocarcinoma,
                                               Breast Invasive Carcinoma, 
                                               Breast Lobular Carcinoma,
                                               Cervical Adenocarcinoma,
                                               Colorectal Cancer, 
                                               Esophageal Carcinoma,
                                               Kidney Renal Clear Cell Carcinoma, 
                                               Kidney Chromophobe,
                                               Liver Hepatocellular Carcinoma,
                                               Lung Adenocarcinoma,
                                               Ovarian Serous Cystadenocarcinoma,
                                               Pancreatic Ductal Adenocarcinoma,
                                               Pancreatic Endocrine Neoplasmas,
                                               Prostate Adenocarcinoma,
                                               Stomach Adenocarcinoma,
                                               Papillary Thyroid Carcinoma, 
                                               Uterine Corpus Endometrial Carcinoma,
                                               Head and Neck Squamous Cell Carcinoma,
                                               Cervical Squamous Cell Carcinoma,
                                               Lung Squamous Cell Carcinoma,
                                               Chondroblastoma,
                                               Chordoma,
                                               Leiomyosarcoma
                                               and 
                                               Osteosarcoma

                                  </li>    
                                </ul>
                            <li>Meta-cohort: <strong>Adenocarcinoma</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Cholangiocarcinoma,
                                               Breast Invasive Carcinoma, 
                                               Breast Lobular Carcinoma,
                                               Cervical Adenocarcinoma,
                                               Colorectal Cancer, 
                                               Esophageal Carcinoma,
                                               Kidney Renal Clear Cell Carcinoma, 
                                               Kidney Chromophobe,
                                               Liver Hepatocellular Carcinoma,
                                               Lung Adenocarcinoma,
                                               Ovarian Serous Cystadenocarcinoma,
                                               Pancreatic Ductal Adenocarcinoma,
                                               Prostate Adenocarcinoma,
                                               Stomach Adenocarcinoma,
                                               Papillary Thyroid Carcinoma
                                               and 
                                               Uterine Corpus Endometrial Carcinoma
                                  </li>  
                                </ul>
                            <li>Meta-cohort: <strong>Squamous</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Head and Neck Squamous Cell Carcinoma,
                                               Cervical Squamous Cell Carcinoma
                                               and 
                                               Lung Squamous Cell Carcinoma
                                  </li>  
                                </ul>

                            <li>Meta-cohort: <strong>Sarcoma</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Chondroblastoma,
                                               Chordoma,
                                               Leiomyosarcoma
                                               and 
                                               Osteosarcoma
                                  </li>  
                                </ul>
                            <li>Meta-cohort: <strong>Giloma</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Pilocytic Astrocytoma, Glioblastoma Multiforme and Lower Grade Glioma 
                                  </li>  
                                </ul>  
                            <li>Meta-cohort: <strong>Hematopoietic system</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes B-cell non-Hodgkin Lymphoma,
                                               Chronic Lymphoctytic Leukemia, 
                                               Undifferentiable Lymphoma,
                                               Acute Myeloid Leukemia,
                                               Chronic Myelomonocytic Leukaemia
                                               and
                                               Essential Thrombocythaemia
                                  </li>  
                                </ul>
                          </ul>

                        <br/>
                        <strong>By organ system:</strong>
                          <ul>     
                            
                            <li>Meta-cohort: <strong>Digestive tract</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Liver Hepatocellular Carcinoma,
                                               Colorectal Cancer,
                                               Pancreatic Ductal Adenocarcinoma,
                                               Esophageal Carcinoma,
                                               Stomach Adenocarcinoma
                                               and 
                                               Cholangiocarcinoma
                                  </li>  
                                </ul>
                            
                            <li>Meta-cohort: <strong>Kidney</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Kidney Renal Clear Cell Carcinoma and 
                                               Kidney Chromophobe
                                  </li>  
                                </ul>
                            <li>Meta-cohort: <strong>Lymphatic system</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes B-cell non-Hodgkin Lymphoma,
                                               Chronic Lymphoctytic Leukemia
                                               and 
                                               Undifferentiable Lymphoma
                                  </li>  
                                </ul>
                            <li>Meta-cohort: <strong>Breast</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Breast Invasive Carcinoma and 
                                               Breast Lobular Carcinoma
                                  </li>  
                                </ul>
                            <li>Meta-cohort: <strong>Female reproductive system</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Breast Invasive Carcinoma, 
                                               Breast Lobular Carcinoma,
                                               Cervical Adenocarcinoma, 
                                               Cervical Squamous Cell Carcinoma,
                                               Ovarian Serous Cystadenocarcinoma
                                               and 
                                               Uterine Corpus Endometrial Carcinoma 
                                  </li>  
                                </ul>
                            <li>Meta-cohort: <strong>Central nerve system</strong>, (Rheinbay et al., Nature 2020)</li>
                                <ul>
                                  <li>includes Pilocytic Astrocytoma, 
                                               Medullablastoma, 
                                               Glioblastoma Multiforme 
                                               and 
                                               Lower Grade Glioma 
                                  </li>  
                                </ul>  
                          </ul>
                      </ul>


                    </p>
                    <p>
                     <strong>Single cancer type:</strong> 

                      <ul>
                        <li><strong>B-ALL</strong>, B-Cell Acute Lymphoblastic Leukemia</li>  
                        <li><strong>BLCA</strong>, Bladder Urothelial Carcinoma</li>
                        <li><strong>BRCA</strong>, Breast Invasive Carcinoma</li>
                        <li><strong>CLL</strong>, Chronic Lymphoctytic Leukemia</li>
                        <li><strong>COAD</strong>, Colon Adenocarcinoma</li>
                        <li><strong>DLBC</strong>, Lymphoid Neoplasm Diffuse Large B-cell Lymphoma</li>
                        <li><strong>ESCA</strong>, Esophageal Carcinoma</li>
                        <li><strong>ETMR</strong>, Embryonal Tumor with Multilayered Rosettes</li>
                        <li><strong>GBM</strong>, Glioblastoma Multiforme</li>
                        <li><strong>HGG</strong>, High-grade Glioma</li>
                        <li><strong>HNSC</strong>, Head and Neck Squamous Cell Carcinoma</li>
                        <li><strong>KICH</strong>, Kidney Chromophobe</li>
                        <li><strong>KIRC</strong>, Kidney Renal Clear Cell Carcinoma</li>
                        <li><strong>LAML</strong>, Acute Myeloid Leukemia</li>
                        <li><strong>LGG</strong>, Lower Grade Glioma</li>
                        <li><strong>LIHC</strong>, Liver Hepatocellular Carcinoma</li>
                        <li><strong>LUAD</strong>, Lung Adenocarcinoma</li>
                        <li><strong>LUSC</strong>, Lung Squamous Cell Carcinoma</li>
                        <li><strong>Lymph-BNHL</strong>, B-cell non-Hodgkin lymphoma</li>
                        <li><strong>MALY</strong>, Malignant Lymphoma</li>
                        <li><strong>MB</strong>, Medullablastoma</li>
                        <li><strong>OV</strong>, Ovarian Serous Cystadenocarcinoma</li>
                        <li><strong>PA</strong>, Pilocytic Astrocytoma</li>
                        <li><strong>PACA</strong>, Pancreatic Ductal Adenocarcinoma</li>
                        <li><strong>Pan-Endocrine</strong>, Pancreatic Endocrine Neoplasmas</li>
                        <li><strong>PRAD</strong>, Prostate Adenocarcinoma</li>
                        <li><strong>RECA</strong>, Kidney Renal Papillary Cell Carcinoma</li>
                        <li><strong>RHAB</strong>, Rhabdoid Tumor</li>
                        <li><strong>SKCM</strong>, Skin Cutaneous Melanoma</li>
                        <li><strong>STAD</strong>, Stomach Adenocarcinoma</li>
                        <li><strong>THCA</strong>, Papillary Thyroid Carcinoma</li>
                        <li><strong>UCEC</strong>, Uterine Corpus Endometrial Carcinoma</li>
			<li><strong>MALY-DE</strong>, Malignant Lymphoma - DE</li>
                        <li><strong>T-ALL</strong>, T-cell acute lymphoblastic leukemia</li>
                       </ul>
                    </p> 
                    
                    <br/>
                    <h4><strong>What is the confidence in the Element-to-Gene Association?</strong></h4>
                    <hr/>
                    <p>
                       Elements with <strong>“experimental validation”</strong> tend to be have more confident associations
                       to genes where gene expression is affected by perturbation of the non-coding region.
                    </p> 

                    <br/>
                    <h4><strong>Where can I see the exact somatic mutation positions?</strong></h4>
                    <hr/>
                    <p>
                        We note that we are unable to provide the exact locations of the somatic 
                        mutations since this information is often not included in the original publication. 
                        However, most papers included in the database used whole-genome sequencing of 
                        cancer samples by the <a href="https://portal.gdc.cancer.gov/" target="_blank" rel="noopener noreferrer">
                        TCGA (https://portal.gdc.cancer.gov/)
                        </a> 
                        &nbsp;or 
                        <a href="https://dcc.icgc.org/" target="_blank" rel="noopener noreferrer"> ICGC (https://dcc.icgc.org/) </a>consortia. 
                        We will also try to include this information (when available) in the next version of the database.
                    </p>

                    <br/>
                    <h4><strong>I have recently identified and published novel non-coding cancer drivers. 
                      <br/>How can my list be included in CNCDatabase ?</strong></h4>
                    <hr/>
                    <p>Through the “Submission” page of the web interface, users can submit new non-coding 
                       cancer drivers to the CNCDatabase. A valid data submission contains a text file with
                        columns for the publication id (PMID), cancer type, gene name, non-coding 
                        element type, element-to-gene association description, cohort size, fraction of 
                        mutated samples, evidence type, evidence method and evidence description. 
                        Then, users will receive separate email notifications to track data submission progress. 
                        The curators of CNCDatabase will manually check the submitted files to ensure the 
                        submitted data is consistent with the database annotation format. When submitted data 
                        passes the curator check,&nbsp;  
                        <a href="https://github.com/khuranalab/CNCDatabase/tree/master/data_pipeline" target="_blank" rel="noopener noreferrer">
                           the CNCDatabase data pipeline </a>
                          will be used to split and store submitted data into the PostgreSQL database and 
                          the new data will be included in the next release of CNCDatabase.
                    </p> 
                    
                    <br/>


                    <h4><strong>How many drivers does each publication included in CNCDatabase contribute ?</strong></h4>
                    <hr/>
                    <p>Please see the plot:</p> 
                      <center>
                        <img src={dataSummary_figure} alt="dataSummary" width="90%" height="100%"/>
                      </center>


                    <br/>
                    <h4><strong>Acknowledgements</strong></h4>
                    <hr/>
                    <p>
                      We thank Douglas Duckworth from Weill Cornell Supercomputing Unit for helps in the
                       website deployment. We also thank Ann Palladino, Sandra Cohen, Tawny Cuykendall,
                        Erica Duo Xu, Andre Forbes at the Khurana lab for valuable suggestions.     
                    </p>
                    {/*<div>
                        <h4><strong>Tutorial 1: example queries</strong></h4>
                        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vTHblNfskQLj6NzNSE-9BT0XckLc_T7NqEC31OpBjvnRkxW_sE68FHnFgicnGDd7BYm7rq7zdAkGrwu/embed?start=false&loop=false&delayms=60000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                    </div>*/}
               </div>

               <div className="col-lg-2">
               </div>         
          </div>
      </div>
    );
  }
}

export default Documentation;
