import { FETCH_SEARCH_PARAMETERS, INIT_SEARCH_PARAMETERS } from '../actions/types';

export default function(state = [], action) {

  switch (action.type) {

    case FETCH_SEARCH_PARAMETERS:
      console.log('Action received (search parameters)', action);
      return action.payload;
    
    case INIT_SEARCH_PARAMETERS:
        console.log('Action received(init search parameters)', action);
        return action.payload;  

    default:
      return state;

  }

}
