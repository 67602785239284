import React, { Component } from 'react';
//import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//import { Button } from 'reactstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import ModalExample2 from './LoginModal_v2';
//# Home, Search, Download, Help, Contact
import './css/header_setup.css';
import NavLink2 from './NavLink';
import logo from './img/cncdatabase_logo_v2.png';

// reference to solve bootstrap navbar-toogle in react
//https://medium.com/@johnne01/how-to-toggle-bootstrap-navbar-collapse-button-in-react-without-jquery-1d5c2fb0751c


class Header extends Component {

    state = {
        links: [
            {path: "/", text: "Home", isActive: true},
            {path: "/search", text: "Search", isActive: false},
            {path: "/dataset", text: "Download", isActive: false},
            {path: "/submission", text: "Submission", isActive: false},
            {path: "/documentation", text: "Documentation", isActive: false},
          ],
        isNavCollapsed: true   
    }

    handleClick(i) {
        const links = this.state.links.slice(); 
        for (const j in links) {
          links[j].isActive = i === j ;
        }
        this.setState({links: links});
    }

    handleNavCollapse = () => {
        this.setState( prevState => ({isNavCollapsed: !prevState.isNavCollapsed}) );
    }


    render(){

            return (
                <nav class="d-flex flex-row navbar navbar-expand-lg navbar-custom">
                
                <Link to="/" onClick={() => {this.handleClick(0)}}>
                    <img className="ml-2" src={logo} alt="cncdatabase" width="40" height="30"/>
                </Link>

                <Link className="navbar-brand"
                         to="/"
                         onClick={() => {this.handleClick(0)}}
                >
                    <strong>CNCDatabase</strong>
                </Link>



                {/* navbar toggle section */}
                <button className="navbar-toggler" 
                        type="button" 
                        data-toggle="collapse" 
                        data-target="#navbarNavAltMarkup" 
                        aria-controls="navbarNavAltMarkup" 
                        aria-expanded={!this.state.isNavCollapsed ? true : false} 
                        aria-label="Toggle navigation" 
                        onClick={ this.handleNavCollapse }
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
            
                {/* navbar menu */}
                <div className={ `${this.state.isNavCollapsed ? "collapse": ""} navbar-collapse`} id="navbarNavAltMarkup">
                    <ul className="navbar-nav">
                       {this.state.links.map((link, i) => 
                            <NavLink2
                                path={link.path} 
                                text={link.text} 
                                isActive={link.isActive}
                                key={link.path} 
                                onClick={() => {this.handleClick(i)}}
                            /> 
                        )}
                    
                    </ul>
                </div>


                </nav>
            );

    }
}

export default Header;

