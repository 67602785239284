import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

/*<li className={"nav-item " + (this.props.isActive ? "active": "")}>*/
// https://www.turtle-techies.com/post/react-navbar-with-bootstrap-4/
class NavLink2 extends Component {

    render() {
        return (
          
            <li className="nav-item">
                  <NavLink 
                      className="nav-link"
                      activeClassName="active" 
                      to={this.props.path}
                      exact
                      onClick={() => this.props.onClick()}
                  >
                   {this.props.text}
                 </NavLink>
            </li> 
          
          
        );
    }
}

export default NavLink2;