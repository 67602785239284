import React, { useMemo } from "react";
import { connect } from "react-redux";
import { fetchCancerDriverList } from "../../actions";

import Table from "./Table_v5";
import "./css/Table.css";
import ReactTooltip from 'react-tooltip';

const ReactTableContainer = ({dataCancerDriverList}) => {

  const data = dataCancerDriverList;

  // debug line
  //console.log("table data",data)


  const columns = useMemo(
    () =>[
          
          {
            Header: <span title="PMID">PMID</span>,
            accessor: "pmid",
            Cell: ({ cell: { value } }) => {
                 return (
                   <div> 
                     <a target='_blank' rel="noopener noreferrer" href={"https://www.ncbi.nlm.nih.gov/pubmed/" + value }>{value}</a>
                  </div>
                 ); 
            }
          },
          {
            Header: "Cancer Type",
            accessor: "cancer_short_name",
            Cell: ({ row: { original } }) => {

              const value = original.cancer_short_name
              const fullName = original.cancer_full_name

              //console.log("cell value", original) 

              return (
                <div>
                    <a data-tip={`${fullName}`} data-for='cancer_short_name'> {value} </a>
                    
                    <ReactTooltip
                    className='tooltip-width' 
                    id='cancer_short_name' 
                    place="right" 
                    type="light" 
                    effect="solid"
                    border={true}
                    getContent={(fullName) => (
                            <div>
                                <p><strong>Cancer Type</strong></p>
                                <hr/>
                                <ul>
                                  <li>{fullName}</li>
                                </ul>
                            </div>
                    )}
                    />
                   
                </div>
              ); 
         }
          },
        
          {
            Header: "Gene Name",
            accessor: "gene_symbol",
          },
          {
            Header: "COSMIC(Cancer Gene)",
            accessor: "cosmic_cgc"
          },
          {
            Header: "Element",
            accessor: "functional_element_type",
            Cell: ({ row: { original } }) => {

              const value = original.functional_element_type
              const fullNameIdx = original.pmid + "#" + original.element_description + "#" + original.element_to_gene_association_description
              const fullNameDescription = original.element_description
              const fullNameAssociationDescription = original.element_to_gene_association_description

              //console.log("cell value", original) 
              //console.log("fullNameIdx", fullNameIdx) 
              //console.log("fullNameDescription", fullNameDescription) 


              return (
                <div>
                    <a data-tip={`${fullNameIdx}`} data-for={`${fullNameIdx}`}> {value} </a>
                    
                    <ReactTooltip
                      className='tooltip-width' 
                      id={`${fullNameIdx}`} 
                      place="right" 
                      type="light" 
                      effect="solid"
                      border={true}
                      
                      getContent={(fullNameIdx) => (

                              

                              
                              <div>
                                  <p><strong>Element and Element-to-gene association Description</strong></p>
                                  <hr/>
                                  <ul>
                                    <li>{fullNameDescription}</li>
                                    { fullNameAssociationDescription && <li> {fullNameAssociationDescription} </li> }
                                  </ul>


                              </div>
                      )}
                    />
                   
                </div>
              ); 
            }
          },
          {
            Header: "Cohort Size",
            accessor: "cancer_cohort_size"
          },
          {
            Header: "Mutated Samples",
            accessor: "num_of_mutated_samples",

          },
          {
            Header: "Evidence Type",
            accessor: "evidence_type"
          },
          {
            Header: "Evidence Method",
            accessor: "evidence_method",
            Cell: ({ row: { original } }) => {

              const value = original.evidence_method
              const fullName = original.evidence_description

              //console.log("cell value", original) 
              
              // to solve tool-tip width
              //https://github.com/wwayne/react-tooltip/issues/109

              return (
                <div>
                    <a data-tip={`${fullName}`} data-for='evidence_method'> {value} </a>
                    
                    <ReactTooltip
                    className='tooltip-width' 
                    id='evidence_method' 
                    place="left" 
                    type="light" 
                    effect="solid"
                    border={true}
                    getContent={(fullName) => (
                            <div>
                                <p><strong>Evidence Description</strong></p>
                                <hr/>
                                <ul>
                                  <li>{fullName}</li>
                                </ul>
                            </div>
                    )}
                    />
                   
                </div>
              ); 
         }
          },
      
    ],
    []
  );

  //const [data, setData] = useState([]);

  //useEffect(() => {
  //  (async () => {
  //    const result = await axios("https://api.tvmaze.com/search/shows?q=snow");
  //    setData(result.data);
  //  })();
  //}, []);

  //console.log("react table data:",data)

  return (
    <div className="react-table-main">
      <Table columns={columns} data={data} />
    </div>
  );
}

//export default ReactTableContainer;

const mapStateToProps = ( state ) => {
    return { dataCancerDriverList: state.dataCancerDriverList };
}
export default connect( mapStateToProps, { fetchCancerDriverList })(ReactTableContainer);
