import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import ReactGA from 'react-ga'
import { Route } from 'react-router-dom'
import ReactGA from "react-ga4";

// https://vanja.gavric.org/blog/integrate-google-analytics-with-react-router-v4/

class GoogleAnalytics extends Component {
  componentDidMount () {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    )
  }

  componentDidUpdate ({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props
    const isDifferentPathname = pathname !== prevLocation.pathname
    const isDifferentSearch = search !== prevLocation.search

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search)
    }
  }

  logPageChange (pathname, search = '') {
    const page = pathname + search
    const { location } = window
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    })
    console.log(page);
    ReactGA.pageview(page)
  }

  render () {
    return null
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
}

const RouteTracker = () =>
  <Route component={GoogleAnalytics} />

// old UA-180328290-1
const init = (options = {}) => {
    ReactGA.initialize('G-WJG7SKLL42',{
        debug: false,
        cookieDomain: 'auto'
        //testMode: true
        });
}

export default {
  GoogleAnalytics,
  RouteTracker,
  init
}
